import React from 'react';

import NewDesignLayout from '../../app/layout/NewDesignLayout';

import MyAccountLoginPageNewDesignContent from './MyAccountLoginPageNewDesignContent';

export const MyAccountLoginPageNewDesign: React.FC = () => {
  return (
    <NewDesignLayout>
      <MyAccountLoginPageNewDesignContent />
    </NewDesignLayout>
  );
};

MyAccountLoginPageNewDesign.displayName = 'CreateClaim';
